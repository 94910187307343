<template>
  <!-- 开票管理 -->
  <div class="box-card" style="text-align: left">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="集团：">
        <el-select popper-class="my-select" clearable v-model="queryForm.group_id" filterable default-first-option
          placeholder="请选择集团" @change="onSubmit">
          <el-option v-for="item in groupList" :key="item._id" :label="item.group_name" :value="item._id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="开票管理">
      <template slot="heard-right">
        <div class="right">
          <lbButton icon="xinzeng" @click="showInvoiceSet = true">新增</lbButton>
        </div>
      </template>
      <div>
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="invoice_time">
              <span>{{ `${row.invoice_time}天后允许开票` }}</span>
            </template>
            <template slot-scope="{row}" slot="goodsname">
              <span>{{ row.invoice_commodity[0].goodsname }}</span>
            </template>
            <template slot-scope="{row}" slot="taxrate">
              <span>{{ `${row.invoice_commodity[0].taxrate}%` }}</span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              <span>
                {{ validDateTime(row.create_time) }}
              </span>
            </template>
             <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editit(row)"></lbButton>
              <lbButton type="err" icon="shanchu1" hint="删除" @click="deletefu(row)">删除</lbButton>
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe>
          <el-table-column prop="group_name" label="集团名称"></el-table-column>
          <el-table-column prop="countP" label="停车场"></el-table-column>
          <el-table-column prop="invoice_platform" label="开票平台"></el-table-column>
          <el-table-column prop="invoice_time" label="可开票时间">
            <template slot-scope="scope">
              <span>{{ `${scope.row.invoice_time}天后允许开票` }}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品名称">
            <template slot-scope="scope">
              <span>{{ scope.row.invoice_commodity[0].goodsname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税率">
            <template slot-scope="scope">
              <span>{{ `${scope.row.invoice_commodity[0].taxrate}%` }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" max-width="130">
            <template slot-scope="scope">
              <span>
                {{ validDateTime(scope.row.create_time) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" max-width="100">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editit(scope.row)"></lbButton>
              <lbButton type="err" icon="shanchu1" hint="删除" @click="deletefu(scope.row)">删除</lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" ></el-pagination>
      </div>
    </le-card>
    <!-- 主要内容 -->
    <!-- 新增发票 -->
    <lebo-dialog :isShow="showInvoiceSet" width="73%" title="新增发票" :footerSlot="true"
      @close="showInvoiceSet = false">
      <invoiceSet :id="'0'" :groupId="'0'" :comefrom="'1'" @closeDialog="closeInvoiceSet" v-if="showInvoiceSet">
      </invoiceSet>
    </lebo-dialog>
    <!-- 编辑发票 -->
    <lebo-dialog :isShow="showEditInvoiceSet" width="73%" title="编辑发票" :footerSlot="true"
      @close="showEditInvoiceSet = false">
      <invoiceSet :id="dialogId" :groupId="dialogGroupId" :comefrom="'1'" @closeDialog="closeInvoiceSet"
        v-if="showEditInvoiceSet"></invoiceSet>
    </lebo-dialog>
  </div>
</template>

<script>
import { getConfiguredList, getAdminBillList, deleteInvoice } from '@/api/financialStatement'
import { mapGetters } from 'vuex'
import invoiceSet from '../components/invoiceSet/index.vue'
export default {
  components: { invoiceSet },
  data () {
    return {
      // 查询表单
      queryForm: {
        group_id: '',
        PageSize: 10,
        PageIndex: 1
      },
      groupList: [],
      // 表格数据
      tableData: [],
      dialogForm: {
        name: ''
      },
      dialogRules: {
        name: [{ required: true, message: '请输集团名称', trigger: 'blur' }]
      },
      TotalCount: 0,
      role_id: [],
      showEditInvoiceSet: false,
      showInvoiceSet: false,
      dialogId: '',
      dialogGroupId: '', // 集团id
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '集团名称',
            prop: 'group_name'
          }, {
            label: '停车场',
            prop: 'countP'
          }, {
            label: '开票平台',
            prop: 'invoice_platform'
          }, {
            label: '可开票时间',
            prop: 'invoice_time',
            slot: true
          }, {
            label: '商品名称',
            prop: 'goodsname',
            slot: true
          }, {
            label: '税率',
            prop: 'taxrate',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  created () { },
  mounted () {
    this.requestAdminBillList()
    this.requestGroupConfigured()
  },
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestAdminBillList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestAdminBillList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestAdminBillList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestAdminBillList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestAdminBillList()
    // },
    // 编辑
    editit (row) {
      this.dialogId = row._id
      this.dialogGroupId = row.group_id
      this.showEditInvoiceSet = true
      // this.$router.push({
      //   path: `/invoice-set/${row._id}/${row.group_id}/1`
      // })
    },
    // addfu () {
    //   this.$router.push({
    //     path: '/invoice-set/0/0/1'
    //   })
    // },
    deletefu (item) {
      var that = this
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          that.requestDelete({ _id: item._id })
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    async requestAdminBillList () {
      const res = await getAdminBillList(this.queryForm)
      this.tableData = res.Data.DataList
      this.TotalCount = res.Data.TotalCount
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    async requestGroupConfigured () {
      const res = await getConfiguredList()
      if (res && res.Code === 200) {
        this.groupList = res.Data
      }
    },
    async requestDelete (params) {
      await deleteInvoice(params)
      this.requestAdminBillList()
    },
    closeInvoiceSet () {
      this.showInvoiceSet = false
      this.showEditInvoiceSet = false
      this.requestAdminBillList()
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
}
.demo-form-inline {
  /* float: left; */
  display: flex;
  justify-content: start;
}

/* // .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// } */
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}</style>
